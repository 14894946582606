import axios from 'axios';
import { BASE_URL, BASE_URL_GO, HEADER_FILE_NAME } from '../app/constants';
import { handleSuccess, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';
import { getAuthToken } from '../utils/AuthToken';

export async function booksImportStatus(searchStr = '') {
  const url = `${BASE_URL}/api/v1/gst2a/books-import-status${searchStr}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadBookTemplate() {
  try {

    // If using S3 - uncomment the following and comment out the Application APIs
    //const purchase_book_url = 'https://expressgst.s3.ap-south-1.amazonaws.com/excel-template/GSTR2_Excel_Workbook_Template.xlsx';
    //const config = {responseType: 'blob'};

    const purchase_book_url = `${BASE_URL}/api/v1/gst2a/excel-template`;
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(purchase_book_url, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'GSTR2_Excel_Workbook_Template.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadBookSaleTemplate() {
  try {

    // If using S3 - uncomment the following and comment out the Application APIs
    //const sale_book_url = 'https://expressgst.s3.ap-south-1.amazonaws.com/excel-template/GSTR1_Excel_Workbook_Template.xlsx';
    //const config = { responseType: 'blob' };

    const sale_book_url = `${BASE_URL}/api/v1/gstr1/books/excel-template`;
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(sale_book_url, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'GSTR1_Excel_Workbook_Template.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadBookSaleConsolidatedTemplate() {
  try {

    const sale_book_url = `${BASE_URL}/api/v1/gstr1/books/consolidated-excel-template`;
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(sale_book_url, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    const file_name = result.headers[HEADER_FILE_NAME] ?? "GSTR1_Excel_Workbook_Consolidated_Template.xlsx";
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importBooks(data, book, startUploading) {
  const url = `${BASE_URL}/api/v1/gst2a/import-books`;
  try {
    const config = {
      ...getConfig(), 'Content-Type': 'multipart/form-data', onUploadProgress: progressEvent =>
        startUploading(book, parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))

    };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function deleteBook(data) {
  const url = `${BASE_URL_GO}/api/v1/gst2a/imported-books`;

  try {
    const result = await axios.delete(url, {
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken(true)}`,
      }
    },);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getAllBooksImportStatus(searchStr = '') {
  const url = `${BASE_URL_GO}/api/v1/books/import-status?${searchStr}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function deleteSalesBook(company_id, year, month) {
  const url = `${BASE_URL}/api/v1/gstr1/books/delete-all?company_id=${company_id}&return_year=${year}&return_month=${month}`;

  try {
    const result = await axios.delete(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function deleteSalesBook_1A(company_id, year, month) {
  const url = `${BASE_URL}/api/v1/gstr1a/books/delete-all?company_id=${company_id}&return_year=${year}&return_month=${month}`;

  try {
    const result = await axios.delete(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importSalesBooks(data, book, startUploading) {
  const url = `${BASE_URL}/api/v1/gstr1/books/import`;

  try {
    const config = {
      ...getConfig(), 'Content-Type': 'multipart/form-data', onUploadProgress: progressEvent =>
        startUploading(book, parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))

    };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importConsolidatedSalesBooks(data, book, startUploading) {
  const url = `${BASE_URL}/api/v1/gstr1/books/import-consolidated-data`;

  try {
    const config = {
      ...getConfig(), 'Content-Type': 'multipart/form-data', onUploadProgress: progressEvent =>
        startUploading(book, parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))

    };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function importSingleBooks(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/import`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function importConsolidatedBooks(data) {
  const url = `${BASE_URL}/api/v1/gstr1/books/import-consolidated-data`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function sendGSTR1ReportEmail(data) {
  const url = `${BASE_URL}/api/v1/gstr1/send-pdf-mail`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function sendGSTR1AReportEmail(data) {
  const url = `${BASE_URL}/api/v1/gstr1a/send-pdf-mail`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function tallyMapCompany(data) {
  const url = `${BASE_URL_GO}/api/v1/tally/map-company`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function tallyUnMapCompany(data) {
  const url = `${BASE_URL_GO}/api/v1/tally/unmap-company`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function tallyMappingSyncStatus(company_id) {
  const url = `${BASE_URL_GO}/api/v1/tally/mapping-sync-status?company_id=${company_id}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function toggleCompanySyncType(data) {
  const url = `${BASE_URL_GO}/api/v1/tally/company-sync-type`;
  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function sendTailyMail(data) {
  const url = `${BASE_URL}/api/v1/tally/email-client-access`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadBookPurchaseConsolidatedTemplate() {
  try {

    const sale_book_url = `${BASE_URL}/api/v1/gst2a/consolidated-excel-template`;
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(sale_book_url, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    const file_name = result.headers[HEADER_FILE_NAME] ?? "GSTR2_Excel_Workbook_Consolidated_Template.xlsx";
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function importConsolidatedPurchaseBooks(data, book, startUploading) {
  const url = `${BASE_URL}/api/v1/gst2a/import-consolidated-books`;
  try {
    const config = {
      ...getConfig(), 'Content-Type': 'multipart/form-data', onUploadProgress: progressEvent =>
        startUploading(book, parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))

    };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getLastBookDataUpdatedDate(company_id) {
  const url = `${BASE_URL_GO}/api/v1/gst2a2b/last-uploaded-date?company_id=${company_id}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};
