/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import NumericInput from "../../components/NumericInput";
import { verify2ADataCatcha } from "../../api/2adataAPI";
import "./UpdateCredentials.css";
import {
  faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OtpVerifiyForCaptcha from "../../components/profile/OtpVerifiyForCaptcha";

const UpdateCredentials = ({ history, ...props }) => {
  const {
    company_id,
    year,
    handleClose,
    refreshSupplierList,
    headerData,
    gstportalUsername,
    getCaptchaData,
    getCaptcha,
    verifyCaptch,
    onSubmit,
  } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaId, setCaptchaId] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [otpRequired, setOtpRequired] = useState(false)

  const getCaptchaNew = () => {
    setCaptchaImg(getCaptchaData.captcha_image);
    setCaptchaId(getCaptchaData.gpc_id);
    setCaptcha("");
    setCaptchaLoading(false);
  };

  const loadCaptcha = () => {
    setError(null);
    setCaptchaLoading(true);
    getCaptcha();
  };

  const verifyCatpcha = (props) => {
    let w = 0;
    if (password.length === 0) {
      setPasswordError("Please enter Password!");
      w++;
    }
    if (password.length < 8 || password.length > 16) {
      setPasswordError("Password should be of 8 to 15 characters!");
      w++;
    }
    if (!props?.otp) {
      if (captcha.length === 0) {
        setError("Please enter captcha!");
        return;
      }
      if (captcha.length < 6) {
        setError("Please enter valid captcha!");
        return;
      }
    }

    if (w > 0) {
      return;
    }

    if (verifyCaptch) {
      const data = {
        gpc_id: captchaId,
        ...(props?.otp ? { otp: props.otp } : { captcha_text: captcha }),
        company_id,
        priority: "GST2A",
        year,
        gstportal_password: password,
      };

      async function fetchEverything() {
        async function fetch2AData() {
          const result = await verify2ADataCatcha(data);
          let return_filling_status_data = localStorage.getItem(
            "return-filling-status-data-v4"
          );
          return_filling_status_data = return_filling_status_data
            ? JSON.parse(return_filling_status_data)
            : [];
          let current_filling_return_data = return_filling_status_data.filter(
            (return_data, index) => {
              return return_data.gst_number !== headerData.gst_number;
            }
          );
          localStorage.setItem(
            "return-filling-status-data-v4",
            JSON.stringify(current_filling_return_data)
          );

          handleClose(false);
          refreshSupplierList(result.data.message, true);
        }
        try {
          await Promise.all([fetch2AData()]);
          setError(null);
          handleClose()
        } catch (err) {
          if (err?.status === 423) {
            setOtpRequired(true)
          } else {
            //handleClose()
          }
          if (err) {
            setError(err?.data?.errors[0])
          }
        } finally {
          setLoading(false);
        }
      }
      fetchEverything();
    } else {
      async function fetchEverything2() {
        try {
          await onSubmit(captchaId, captcha, password);
        } catch (err) {
          if (err?.status === 423) {
            setOtpRequired(true)
          } else {
            handleClose()
          }
          if (err) {
            setError(err?.data?.errors[0])
          }
        } finally {
          setLoading(false);
        }
      }
      fetchEverything2();
    }

    setLoading(true);
  };

  const handleCloseOtp = () => {
    setOtpRequired(false);
    getCaptcha()
  }
  useEffect(() => {
    getCaptchaNew();
  }, [company_id, getCaptchaData]);



  return (
    <>
      <Modal.Header closeButton className="updatecreaditpopuph">
        <h4 className="updatecreadittile">
          <span className="credcompnameo cmapnameoverflowtext">
            <span
              data-toggle="tooltip"
              data-placement="right"
              title={headerData.company_name}
            >
              {headerData.company_name}
            </span>
          </span>{" "}
          <span className="gstnnotexfs">
            <span className="px-1 modltitlecls">|</span> {headerData.gst_number}
          </span>
          <br />
          <span className="gstnunamefs">
            {" "}
            GSTN Username:{" "}
            <span className="gunametextblod">
              {gstportalUsername}
            </span>
          </span>
        </h4>
      </Modal.Header>
      <Modal.Body className="py-1 px-3">
        <div className="row pt-2">
          <div className="col-md-12">
            <p className="providcredenttextfs">
              It seems your password on GSTN portal has changed. Please provide
              updated GSTN password:{" "}
            </p>
            <div className="row mt-2 ">
              <div className="form-group col-sm-12">
                {showPassword ? (
                  <div className="input-group">
                    <input
                      type="input"
                      className="form-control lg-input form-control credinputtextfs"
                      name="password"
                      placeholder="Enter Password"
                      required=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-prepend eyeipbox uPdcursorPointer" onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </div>
                  </div>
                ) : (
                  <div className="input-group">
                    <input
                      type="password"
                      className="form-control lg-input form-control credinputtextfs"
                      name="password"
                      placeholder="Enter Password"
                      required=""
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onClick={() => setPasswordError('')}
                      autoComplete="new-password"
                    />
                    <div className="input-group-prepend eyeipbox uPdcursorPointer eyeicon_toppadd" onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                  </div>
                )}

                {passwordError ? <span className="signup_error_msg">{passwordError}</span> : null}


              </div>
              <div className="form-group col-sm-6 mb-0">
                <label for="">Captcha</label>
                <div className="input-group mb-1">
                  <div className="captcha_img captcha_imgwidth">
                    {!captchaLoading ? (
                      <img
                        src={`data:image/png;base64, ${captchaImg}`}
                        alt=""
                      />
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-sm-6 mb-0">
                <label for="">&nbsp;</label>
                <div className="input-group">
                  <NumericInput
                    className="form-control inputheight_padd"
                    id="captcha"
                    placeholder="Enter Captcha"
                    value={captcha}
                    maxLength={6}
                    onChange={(event) => setCaptcha(event.target.value)}
                    onClick={() => setError('')}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <div className="form-group col-md-12 mb-0">
                <p className="readcaptcha_textfs">
                  <small>
                    Can't read the captcha?{' '}
                    <span className="reloadcaptcha" onClick={() => loadCaptcha()}>Click here</span>{' '}
                    to refresh
                  </small>
                </p>
              </div>
              {error ?
                <div className="form-group col-md-12 mb-0">
                  <p>
                    <Alert variant="danger">{error}</Alert>
                  </p>
                </div>
                : null}
            </div>
          </div>
          <div className="col-md-12 text-center pt-2 pb-1">
            <Button
              className="btn btn-default updatecredsubbtn"
              onClick={verifyCatpcha}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bookVerify">

      </Modal.Footer>

      {otpRequired && <OtpVerifiyForCaptcha handleClose={handleCloseOtp} callFunc={verifyCatpcha} error={error} setError={setError} />}

    </>
  );
};

export default UpdateCredentials;
